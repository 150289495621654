<template>
	<main>
		<!-- contact-area -->
		<section class="contact-area pb-50 pt-100">
			<div class="container">
				<div class="row">
					<div class="col-xl-12 text-center mb-40">
						<div class="section-title service-title">
							<h2>You are invited !!!</h2>
							<p>We appreciate your interest in our Products and Services.</p>
						</div>
					</div>
				</div>

				<div class="row pt-50">
					<div class="col-lg-4 col-md-6 text-center mb-30">
						<div class="content-wrap">
							<div class="features-icon pb-30">
								<img src="assets/icons/hand-shake.svg" alt="FreightMan" />
							</div>
							<h4>Sales/Marketing</h4>
							<div class="contact_detail pt-20">
								<ul>
									<li><b>Email:</b> sales@xtrm.co.in</li>
									<li><b>Phone:</b> +91 9898 036208</li>
								</ul>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 text-center mb-30">
						<div class="content-wrap">
							<div class="features-icon pb-30">
								<img src="assets/icons/support.svg" alt="FreightMan" />
							</div>
							<h4>Customer Support</h4>
							<div class="contact_detail pt-20">
								<ul>
									<li><b>Ticket System:</b> Submit a ticket</li>
									<li><b>Email:</b> support@xtrm.co.in</li>
									<li><b>Phone:</b> +91 9662 623471</li>
								</ul>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 text-center mb-30">
						<div class="content-wrap">
							<div class="features-icon pb-30">
								<img src="assets/icons/social-media.svg" alt="FreightMan" />
							</div>
							<h4>Social Network</h4>
							<div class="contact_detail pt-20">
								<ul>
									<li><a href="https://www.facebook.com/extremesolutions.in/">Facebook</a></li>
									<li><a href="https://twitter.com/extremetwitts">Twitter</a></li>
									<!-- <li><a href="#">Skype</a></li> -->
									<li><a href="https://www.youtube.com/c/ExtremeSolutionsGandhidham">Youtube</a></li>
									<li><a href="https://www.instagram.com/xtrmcoin">Instagram</a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="contact-area grey-bg pb-50 pt-100">
			<div class="container">
				<div class="row">
					<div class="col-xl-3 col-md-3 text-center text-md-left mb-10">
						<div class="contact-person">
							<h5><b>Visit us at our office</b></h5>
							<h6>Extreme Solutions</h6>
							<p>
								Office No. 11, Komal Complex,<br />
								Plot No. 305, Ward 12/B, <br />Gandhidham, Gujarat, India
							</p>
							<a href="#" class=""
								><i class="far fa-calendar-alt"></i>Mon - Sat 10:00 am - 7:00
								PM</a
							>
						</div>
						<br />
					</div>
					<div class="col-xl-9 col-md-9 text-center text-md-left mb-30">
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3670.8326018965063!2d70.13543831444301!3d23.0665979204707!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3950b98b40277529%3A0x63c24ef6bd53625d!2sExtreme%20Solutions!5e0!3m2!1sen!2sin!4v1614249330446!5m2!1sen!2sin"
							width="100%"
							height="400"
							style="border:0;"
							allowfullscreen=""
							loading="lazy"
						></iframe>
					</div>
				</div>
			</div>
		</section>
		<!-- contact-form -->
		<section class="contact-form pb-100 pt-70">
			<div class="container">
				<div class="row">
					<div class="col-12 text-center">
						<div class="contact-form-title">
							<h2>Fill up the contact form below</h2>
							<p>Fill up this form and submit it.</p>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-8 offset-md-2">
						<form
							id="contact-form"
							@submit="sendmail()"
							action="javascript:void(0)"
							class="contact-form"
							method="POST"
						>
							<div class="row">
								<div class="col-xl-6 col-lg-6 mb-20">
									<input name="name" id="name" type="text" placeholder="Name" />
								</div>
								<div class="col-xl-6 col-lg-6 mb-20">
									<input name="email" id="email" type="email" placeholder="Email" />
								</div>
								<div class="col-xl-6 mb-20">
									<input name="phone-no" id="phone-no" type="text" placeholder="Phone No" />
								</div>
								<div class="col-xl-6 mb-20">
									<input name="subject" id="subject" type="text" placeholder="Subject" />
								</div>
								<div class="col-xl-12 mb-20">
									<textarea
										name="message"
										id="message"
										cols="30"
										rows="10"
										placeholder="Message*"
									></textarea>
								</div>
								<div class="col-xl-12 text-center">
									<button class="btn" type="submit">Submit</button>
								</div>
							</div>
						</form>
						<p class="ajax-response"></p>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
// import '../assets/js/smtp.js'
import {Email} from '../assets/js/smtp.js'
import swal from 'sweetalert2';
window.Swal = swal;
export default {
	name: "contact",
	methods: {
		sendmail(){
			// alert("function called");
			var name = $('#name').val();
			var email = $('#email').val();
			var phone = $('#phone-no').val();
			var subject = $('#subject').val();
			var message = $('#message').val();
			var Body='Name: '+name+'<br>Email: '+email+'<br>Phone: '+phone+'<br>Subject: '+subject+'<br>Message: '+message;

			Email.send({
			    Host : "smtp.sendgrid.net",
			    Port : 25,
			    Username : "apikey",
			    Password : "",
			    To: '',
			    From: "",
			    Subject: "New message on contact from "+name,
			    Body: Body
			}).then(
			        message =>{
			            if(message=='OK'){
			                return new Swal("Thank You For Message Us!", "We will contact you as soon as possible!", "success");
			                // document.getElementById("contact-form").reset();
			            }
			            else{
			                console.error (message);
			                return new Swal("Oops!", "Something went wrong, please try again!", "error");
			                // document.getElementById("contact-form").reset(); 
			            }
			        }
			    );
		}
	}
};
</script>

<style></style>
